/**
 * Import aller vendorspezifischen CSS-Definitionen
 *
 * @copyright 2016 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

/*!
 * Cookiebar
 */

//@import '../vendors/plugins/cookiebar/css/_cookiebar';
//
///*!
// * Swiper
// */
//
//@import '../vendors/plugins/swiper/css/_swiper';
//
///*!
// * Fancybox
// */
//
////@import '../vendors/plugins/fancybox/css/_fancybox';
//@import '../vendors/plugins/fancybox/css/_fancybox-3.1.x';
//
///*!
// * MMenu
// */
//
//@import '../vendors/plugins/mmenu/css/_mmenu';
//@import '../vendors/plugins/mmenu/addons/keyboardnavigation/_keyboardnavigation';
//@import '../vendors/plugins/mmenu/addons/keyboardnavigation/_keyboardnavigation';
//@import '../vendors/plugins/mmenu/addons/navbars/_navbars';
//@import '../vendors/plugins/mmenu/addons/offcanvas/_offcanvas';
//@import '../vendors/plugins/mmenu/extensions/effects/_effects';
//@import '../vendors/plugins/mmenu/extensions/positioning/_positioning';
//
///*!
// * Bootstrap Datetimepicker
// */
//
//@import '../vendors/plugins/bootstrap-datetimepicker/css/_bootstrap-datetimepicker';